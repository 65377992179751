import React from 'react'
const HomeSlider = () => {
  return (
   <>
   <div className="slider-sec"> 
   <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel"> 
  
   <div className="carousel-inner"> 
   <div className="carousel-item active" data-bs-interval="3000"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/sld1.jpg" className="d-block w-100" alt="Gandhi High School, Sidhi - Madhya Pradesh"/> </div> 
   <div className="carousel-item" data-bs-interval="3000"> 
       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/sld2.jpg" className="d-block w-100" alt="Gandhi High School, Sidhi - Madhya Pradesh"/> 
           </div> 
           <div className="carousel-item" data-bs-interval="3000"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/sld3.jpg" className="d-block w-100" alt="Gandhi High School, Sidhi - Madhya Pradesh"/> </div> 
               <div className="carousel-item" data-bs-interval="3000"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/sld4.jpg" className="d-block w-100" alt="Gandhi High School, Sidhi - Madhya Pradesh"/> </div> 
           </div> <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button> <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button> 
   </div> 
       
   <div className="slider-info">
   <div id="carouselExampleCaptions1" className="carousel slide" data-bs-ride="carousel1"> 
   <div className="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button> 
      <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="1" aria-label="Slide 2"></button>  
   </div> 
   <div className="carousel-inner"> 
    
   <div className="carousel-item active" data-bs-interval="3000">
     <div className="slidercircletext">
     <h4>“The roots of education are bitter, but the fruit is sweet”</h4>
     <div className="slider-info-text">
         <h5>-Aristotle</h5>
     </div>
     </div>
</div>
     
   <div className="carousel-item" data-bs-interval="3000">
   <div className="slidercircletext">
   <h4>“The roots of education are bitter, but the fruit is sweet”</h4>
   <div className="slider-info-text">
       <h5>-Aristotle</h5>
   </div>
   </div>
     </div>
    
   </div> 
   </div> 
   </div>
   </div>
   
   </>
  )
}

export default HomeSlider
