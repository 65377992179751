import React from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom'
const SchoolCabinet = () => {
    return (
        <>
            <Header />
            <div className="innerslide Admissionbg">
                <ul className="breadcrumb">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>School Cabinet</li>
                </ul>
            </div>
            <div className="innersec cabinet">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1>School Cabinet</h1>
                        </div>
                        <div className="topperssec">
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Ashim_Ranjan.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Ashim Ranjan Mishra <span>Head Boy </span></p>
                                    <p> X Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Anjali.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Anjali Mishra <span>Head Girl </span></p>
                                    <p> X Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Ved-Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Ved Singh Parihar <span>Vice Head Boy </span></p>
                                    <p> IX Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Tanvi.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Tanvi Vishwakarma  <span>Vice Head Girl  </span></p>
                                    <p> IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Shubham-Dubey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">  Shubham Dubey <span>Discipline Captain Boy</span></p>
                                    <p> X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Mansi-Sahu.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Mansi  Sahu  <span>Discipline Captain Girl</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Kanishk-Tiwari.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Kanishk Tiwari  <span>Discipline Vice Captain Boy</span></p>
                                    <p>IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Anshika-Verma.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Anshika Verma  <span>Discipline Vice Captain Girl</span></p>
                                    <p>IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Vansh_Basantani.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Vansh Basantani <span>Sports Captain Boy</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Rashu_Mishra.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Rashu Mishra  <span>Sports Captain Girl</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Abhinav_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Abhinav Singh<span>Sports Vice Captain Boy</span></p>
                                    <p>IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Niyati_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Niyati Pandey <span>Sports Vice Captain Girl</span></p>
                                    <p>IX Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Aarush_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Aarush Singh <span>literary Captain Boy</span></p>
                                    <p>X Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Ritika_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Ritika Singh <span>Literary Captain  Girl</span></p>
                                    <p>X Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Gyan_Prakash.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Gyan Prakash Patel<span>Literary Vice Captain Boy</span></p>
                                    <p>IX Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Vlrinda_Mishra.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Vlrinda Mishra <span>Literary Vice Captain Girl</span></p>
                                    <p>IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Adarsh_Mishra.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Adarsh Mishra<span>Cultural Captain Boy</span></p>
                                    <p>X Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Aradhya_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Aradhya Pandey <span>Cultural Captain Girl</span></p>
                                    <p>X Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Rudra_Pratap.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Rudra Pratap Mishra <span>Cultural Vice Captain Boy</span></p>
                                    <p>IX Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Anushka_Jaiswal.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Anushka Jaiswal <span>Cultural Vice Captain Girl</span></p>
                                    <p>IX Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Anmol_Tripathi.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Anmol Tripathi <span>Band Captain Boy </span></p>
                                    <p>VIII Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Pihu_Tiwari.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Pihu Tiwari <span>Band Captain Girl</span></p>
                                    <p>VIII Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Ankit_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Ankit Pandey<span>Band Vice Captain Boy</span></p>
                                    <p> VIII Star </p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Anvesha_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Anvesha Pandey <span>Band Vice Captain Girl</span></p>
                                    <p>VIII Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Pratyush_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Pratyush Singh <span>Gandhi House Captain Boy</span></p>
                                    <p>X Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Anushka_Mishra.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Anushka Mishra <span>Gandhi House Captain Girl</span></p>
                                    <p>X Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Pragyan_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Pragyan Singh <span>Gandhi House Vice Captain</span></p>
                                    <p>IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Sakshi_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Sakshi Pandey <span>Gandhi House Vice Captain Girl</span></p>
                                    <p>IX Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Prateek_Tripathi.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Prateek Tripathi    <span>Alphonsa House Captain</span></p>
                                    <p> X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Swara_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Swara Singh <span>Alphonsa House Captain Girl</span></p>
                                    <p>X Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Aayansh_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">  Aayansh Pandey  <span> Alphonsa House Vice Captain</span></p>
                                    <p>IX Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Shreyashi_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Shreyashi Singh <span>Alphonsa House Vice Captain Girl</span></p>
                                    <p>IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Naitik_Tiwari.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">  Naitik Tiwari <span>Kallam House Captain</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Samiksha_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Samiksha Pandey <span>Kalam House Captain Girl</span></p>
                                    <p>X Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Shantanu_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Shantanu Singh<span>Kalam House Vice Captain</span></p>
                                    <p>IX  Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Manya_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Manya Pandey <span>Kalam House Vice Captain Girl</span></p>
                                    <p>IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Devanshik_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Devanshik Singh <span>Mother Teresa House Captain</span></p>
                                    <p>X Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Ichchha_Mishra.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Ichchha Mishra <span>Mother Teresa House Captain Girl</span></p>
                                    <p>X Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Satakshi_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Satakshi Singh  <span>Mother Teresa House vice Captain</span></p>
                                    <p>IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Swapdeep_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Swapdeep Singh <span>Science Club Captain</span></p>
                                    <p>X Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Shalini_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Shalini Singh <span>Science Club Captain Girl</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Aaradhya_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Aaradhya Singh  <span>Maths Club Captain</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Shrishty_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Shrishty Singh <span>Maths Club Captain Girl</span></p>
                                    <p>X Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Krishna_Chattani.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Krishna Chattani  <span>Cyber Club Captain</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Vaishnavi_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Vaishnavi Singh <span>Cyber Club Captain Girl</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Abhiyuday_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Abhiyuday Singh  <span>Social Science Club Captain</span></p>
                                    <p>IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Stuti_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Stuti Pandey <span>Social Science Club Captain Girl</span></p>
                                    <p>IX Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Ujjwal_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">Ujjwal Pandey <span>Lanuage Club Captain</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Sanskriti_Chaturvedi.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Sanskriti Chaturvedi <span>Language Club Captain Girl</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Aditya_Vardhan.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Aditya Vardhan Singh <span>Social Service Club Captain</span></p>
                                    <p>IX Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Pranjali_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername">  Pranjali Singh  <span>Social Service Club Captain Girl</span></p>
                                    <p>X Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Ankur_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Ankur Singh Somvanshi <span>Art Club Captain</span></p>
                                    <p>X Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Anshika_Pandey.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Anshika Pandey <span>Art Club Captain Girl</span></p>
                                    <p>X Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Vivedk_Gautam.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Vivedk Gautam <span>Eco Club Captain</span></p>
                                    <p>X Sun</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Rubi_Shukla.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Rubi Shukla  <span>Eco Club Captain Girl</span></p>
                                    <p>X Star</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Gaurav_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Gaurav Singh <span>Swacchata Club Captain</span></p>
                                    <p>IX Moon</p>
                                </div>
                            </div>
                            <div className="topperdiv">                                
                                <div className="topperdivimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Nitya_Singh.jpg" className="img-fluid" alt="Placeholder" />
                                </div>
                                <div className="topperdivdesc">
                                    <p className="toppername"> Nitya Singh <span>Swacchata Club Captain Girl</span></p>
                                    <p>IX Star</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default SchoolCabinet;