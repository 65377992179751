import React from 'react'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
      <div className="campuslogo">
         <Link to="https://ghssidhi.campussoft.net/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/Campulogo.png" className="img-fluid float-end campus" alt="Gandhi High School, Sidhi - Madhya Pradesh"/></Link>
      </div>
      </>
        
  )
}

export default Campuscare
