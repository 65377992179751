import React from 'react'
import Header from '../Component/Header' 
import Footer from'../Component/Footer'
const Kindergarten = () => {
  return (
     <>
       <Header/>
        <div class="kinerslider">
              <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                        <div class="kinersliderimg">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/kindersliderimg3.png" class="img-fluid kindersliderimg3" /> 
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/kindersliderimg2.png" class="img-fluid kindersliderimg2" /> 
                           
                        </div>
                        <div class="kindericnimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/kindersliderimg1.png" class="img-fluid kindersliderimg1" /> 
                        </div>
                          <h3>Welcome to Kindergarten</h3>
                        <p>Gandhi High School is started in the year 1969. It is a private, unaided, minority running English Medium School recognized under the M.P. Board of Secondary Education Bhopal (M.P) under the general management of the Catholic Diocese of Satna registered under the M.P. Society Registration Act 1964. It is locally run by St. Thomas Church, Sidhi, in collaboration with Rev. Sisters of Franciscan Clarit Congregation, Amala Province, Bhopal (M.P.). The school bears the name Gandhi High School because of the management wanted to have a fitting memorial for the centenary year to remember Gandhi's High ideas of Satyagraha, non - violence, brotherhood, tolerance and swaraj . </p>
                        </div> 
                    </div>
              </div>
        </div>
          <div class="kinderhours">
               <div class="container">
                <div class="row">
                      <div class="col-lg-6">
                          <div class="kinderhoursbox">
                               <div class="kinderimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/schoolhoursicn.png" class="img-fluid" /> 
                               </div>
                               <div class="kinderdesc">
                                  <h4>School Hours (Summer ) </h4>
                                  <p>Classes! - X 7:30am to 1:40pm<br/> Nursery KG - 7:30am to 12:25pm </p>
                               </div>
                          </div>
                      </div>
                      <div class="col-lg-6">
                          <div class="kinderhoursbox">
                               <div class="kinderimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/schoolhoursicn.png" class="img-fluid"/> 
                               </div>
                               <div class="kinderdesc">
                                 <h4>School Hours (Winter ) </h4>
                                 <p>Classes - I - X 9:00am to 3:20pm <br/>Nursery KG - 9:0am to 1:45pm 
</p>
                               </div>
                          </div>
                      </div> 
                     <div class="col-lg-6">
                     <div class="kinderhoursbox">
                          <div class="kinderimg">
                               <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/principalicn.png" class="img-fluid"/> 
                          </div>
                          <div class="kinderdesc">
                             <h4>Time to Meet the Principal</h4>
                             <p>Monday, Tuesday, Thursday & Fridays 9am to 11am</p>
                          </div>
                     </div>
                 </div>
                      <div class="col-lg-6">
                          <div class="kinderhoursbox">
                               <div class="kinderimg">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/officehours.png" class="img-fluid"/> 
                               </div>
                               <div class="kinderdesc">
                                  <h4>Office Hours</h4>
                                  <p>9.00am to 1.00pm</p>
                               </div>
                          </div>
                      </div>
                </div> 
           </div>
           </div>
           <div class="kinderuniform">
           <div class="container kinderuniinfo">
                <div class="col-lg-12">
                    <div class="maintitle"> 
                       <h1> School Uniform </h1></div>
                 </div>
                <div class="row">
                     <div class="col-lg-6">
                         <h5>Nursery to UKG (Boys)</h5>
                         <p>Half sleeved shirt check mixed with white, red and black strips, deep cream full pants, maroon tie, belt, socks and black leather shoes. </p> <p> <strong> Wednesday &  Saturday :</strong> White Shirt &  pants and white canvas shoes &  white Socks.</p>
                     </div>
                     <div class="col-lg-6">
                     <h5>Nursery to UKG (Girls)</h5>
                     <p>Frock, at top deep cream and bottom check mixed with white, red and black strips, maroon tie, belt, socks, red ribbon and black leather shoes. </p> <p> <strong> Wednesday &  Saturday </strong> : White tunic and white blouse, white canvas shoes and white socks.</p>
                     </div>
                </div>
           </div>
        </div>
         <div class="kinderinfo">
              <div class="container">
              <div class="row">
              <div class="col-lg-6">
              <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Infrastructure
                      </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                           <ul>
                              <li>Audio Visual Room </li>
                              <li>Smart Class</li>
                              <li>Kintergarten Park</li>
                           </ul> 
                      </div>
                  </div>
                  </div>
                  <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Admission
                      </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                      <p>Admission is open to all, regardless of caste, creed and social status. Admission to Nursery and above classes starts by r week of March. For a fresh admission, the child should be introduced personally by the parents/ guardian. The admission to all classes (Nursery Et above) are on the basis of personal interaction with child and parents. The students, who seek admission in class - I have to submit a copy of the Birth certificate and mark sheet along with the admission form. </p>
                      </div>
                  </div>
                  </div>
                  <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Contributions
                      </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                      <ul>
                      <li>Fee will be deposited in Union Bank of India, Now going in four installments which includes Monthly contribution and Smart Learning Class (April, June, July , August, September, October, November, December, January, February, March)</li>
                      <li>First installment includes contribution for the School Maintenance, Co-curricular Activities, Examination, Library, Lab, Computer and staff welfare. Contribution for the above are made only once a year.</li>
                      <li>Fee should be paid from date 1 to 15 of April, June, July October and January. If fees are not paid in due time there will be a late fee of Rs 50 for every installment cumulatively.</li>
                      <li>All the fees of the session should be completed before 151" February.</li>
                      <li>Fee details are given in the fee slip book.</li>
                      <li>Fee structure is subject to revision. </li>
                      </ul>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
              <div class="col-lg-6">
                   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/kinderinfo.png" class="img-fluid"/>
                    <div class="kinderinfotop">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/kinderinfo1.png" class="img-fluid kinderinfotopimg"/> 
                    </div>
                    <div class="kinderinfotop1">
                          <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/kinderinfo2.png" class="img-fluid kinderinfotopimg"/> 
                    </div>
              </div>
             </div>
              </div>
         </div>
       <Footer/>
     </>
  )
}

export default Kindergarten
