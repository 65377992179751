import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const ContactUs = () => {
  return (
     <>
       <Header/>
        <div className="innerslide Admissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Contact Us </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Contact Us </h1>
                    </div>
                </div>
                <div className="contact-map">
                 <iframe
                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3632.998050828239!2d81.86974651210696!3d24.416135278131247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3985961b9cb9b0dd%3A0x3947eae4171dc25b!2sGandhi%20High%20School%2C%20Sidhi!5e0!3m2!1sen!2sin!4v1717562784152!5m2!1sen!2sin" width="100%" height="450" style={{border:" 0;"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                 </div>
                 <div className="contact-container">
                 
                        <div className="contact-blk">
                            <div className="contact-icon">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/location1.png" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/>
                            </div>
                            <div className="contact-info">
                                <h6 className="title">Address</h6>
                                <p><Link to="https://maps.app.goo.gl/Vw4Xt58FT3EoRBM87" target="_blank">
                                    Gandhi High School <br />
                                North karoundiya, sidhi (M.P)</Link>
                                </p>
                            </div>
                        </div>
                        <div className="contact-blk">
                            <div className="contact-icon">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/phone1.png" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/>
                            </div>
                            <div className="contact-info">
                                <h6 className="title">Phones</h6>
                                <p><Link to="tel:9424746251">  9424746251</Link>/<Link to="tel:7415857616">  7415857616</Link> </p>
                            </div>
                        </div>
                        <div className="contact-blk">
                            <div className="contact-icon">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/GHSS/public/Images/mail1.png" className="img-fluid" alt="Gandhi High School, Sidhi, MP"/>
                            </div>
                            <div className="contact-info">
                                <h6 className="title">Email</h6>
                                <p><Link to="mailto:gandhihighschoolsidhi@gmail.com "> gandhihighschoolsidhi@gmail.com </Link> </p>
                            </div>
                        </div>
                        </div> 
                      
                      
 </div> 
</div> 
       <Footer/>
     </>
  )
}

export default ContactUs
